// Default for home page. Should add custom, but similar, metaTags
//	for each page as the site expands.
const metaTags = [
    {
        name: "description",
        content:
            "Quickly find the best music video director for your song. Easily collaborate to create the perfect music video."
    },
    {
        name: "keywords",
        content:
            "app, music, video, director, hire, find, contract, create, music video director, music video, make music video, create music video, hire music video director, find artist"
    },
    // Social Media preview configuration
    { property: "og:type", content: "website" },
    { property: "twitter:card", content: "summary" },
    { property: "twitter:site", content: "@axlry" },
    {
        property: "twitter:image",
        content:
            "https://firebasestorage.googleapis.com/v0/b/axlry-8018c.appspot.com/o/website%2Faxlry-logo-white-red-bg.jpg?alt=media&token=fdeae1d0-60d0-4f54-8128-177cf27e93e1"
    },
    {
        property: "og:title",
        content: "AXLRY - Connecting artists and music video directors."
    },
    {
        property: "og:site_name",
        content: "AXLRY"
    },
    { property: "og:url", content: "https://www.axlry.com/" },
    {
        property: "og:image",
        content:
            "https://firebasestorage.googleapis.com/v0/b/axlry-8018c.appspot.com/o/website%2FAXLRY-social-banner-1.png?alt=media&token=b7854bca-6566-4ba2-a929-28f0cbe9c73b"
    },
    { property: "og:image:alt", content: "AXLRY" },
    {
        property: "og:description",
        content:
            "Quickly find the best music video director for your song. Easily collaborate to create the perfect music video."
    }
];

export default metaTags;
