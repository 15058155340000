// EmailVerification page
import React, { Component } from "react";
import { verifyEmail } from "../../../firebase/userManagement";
import PropTypes from "prop-types";
import Layout from "../../../components/Layout";
import styles from "./EmailVerification.module.css";

class EmailVerification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: "loading"
        };

        this.renderState = this.renderState.bind(this);
    }

    static propTypes = {
        oobCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        oobCode: "abc123"
    };

    componentDidMount() {
        const { oobCode } = this.props;

        verifyEmail(oobCode).then(verified => {
            this.setState({
                verified
            });
        });
    }

    renderState = () => {
        const { verified } = this.state;
        let message = "";
        switch (verified) {
            case "success":
                message = "Your account has been verified!";
                break;
            case "loading":
                message = "Loading...";
                break;
            default:
                message = "An error occured, please try again.";
        }
        return <h1 className={styles.text}>{message}</h1>;
    };

    render() {
        return (
            <Layout title={"AXLRY - Verify Email"}>
                <div className={`${styles.page} center-container`}>
                    {this.renderState()}
                </div>
            </Layout>
        );
    }
}

export default EmailVerification;
