// Layout wrapper
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import defaultTags from "../../config/staticRefs/metaTags";

import Navbar from "../Navbar";
import Footer from "../Footer";
import "../../config/styles/layout.css";

const Layout = props => {
    const { children, title, meta } = props;
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <>
                    <Helmet title={title} meta={meta}>
                        <html lang="en" />
                    </Helmet>
                    <Navbar />
                    <div>{children}</div>
                    <Footer />
                </>
            )}
        />
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object)
};

Layout.defaultProps = {
    title: "AXLRY - Connecting artists and music video directors",
    meta: defaultTags
};

export default Layout;
