import { auth, rtdb } from "./index";
// NOTE: Should improve modularity in the future

// User Beta Signup
export function signupBeta(newUser) {
    auth.signInAnonymously().then(() => {
        rtdb.ref("betaList").push(newUser);
    });
}

// Contact Axlry
export function contactAxlry(newMessage) {
    auth.signInAnonymously().then(() => {
        console.log(newMessage);

        rtdb.ref("contactFormMessages").push(newMessage);
    });
}

// Password Reset Verification
export function verifyPasswordReset(oobCode) {
    return auth
        .verifyPasswordResetCode(oobCode)
        .then(email => {
            return email;
        })
        .catch(error => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            console.error(error);
        });
}

// Password Reset
export function resetPassword(oobCode, newPassword) {
    return auth
        .confirmPasswordReset(oobCode, newPassword)
        .then(() => {
            // Password reset has been confirmed and new password updated.
            return "success";
        })
        .catch(error => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            console.error(error);
            return "error";
        });
}

// Email Recovery
export function recoverEmail(oobCode) {
    // Confirm the action code is valid.
    return auth
        .checkActionCode(oobCode)
        .then(info => {
            return auth.applyActionCode(oobCode);
        })
        .then(() => {
            // Account email reverted to restoredEmail
            return "success";
        })
        .catch(error => {
            // Invalid code.
            return "error";
        });
}

// Email Verification
export function verifyEmail(oobCode) {
    // Try to apply the email verification code.
    return auth
        .applyActionCode(oobCode)
        .then(() => {
            // Email address has been verified.
            return "success";
        })
        .catch(error => {
            console.error(error);
            return "error";
        });
}
