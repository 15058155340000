import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
    apiKey: "AIzaSyBNowSXc6z6sY0My5oabrux02ur7SklSTQ",
    authDomain: "axlry-8018c.firebaseapp.com",
    databaseURL: "https://axlry-8018c.firebaseio.com",
    projectId: "axlry-8018c",
    storageBucket: "axlry-8018c.appspot.com",
    messagingSenderId: "909106957663"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

let auth, rtdb;

if (typeof window !== "undefined") {
    rtdb = firebase.database();
    auth = firebase.auth();
}

export { auth, rtdb };
