// Footer component
import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import Logo from "../Images/SmallLogo";
import { Link } from "gatsby";
import styles from "./Footer.module.css";

const IconSize = 25;

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.topRow}>
                    <Link
                        className={styles.logo}
                        style={{ paddingTop: 16 }}
                        to="/"
                        title="AXLRY - Home"
                    >
                        <Logo />
                    </Link>
                    <div className={styles.iconList}>
                        <OutboundLink
                            href="https://www.facebook.com/Axlry-217704278433817/"
                            className={styles.socialLink}
                            rel="external"
                            title="AXLRY Facebook"
                        >
                            <FaFacebookF
                                className={styles.socialIcon}
                                size={IconSize}
                            />
                        </OutboundLink>
                        <OutboundLink
                            href="https://www.instagram.com/axlry/"
                            className={styles.socialLink}
                            rel="external"
                            title="AXLRY Instagram"
                        >
                            <FaInstagram
                                className={styles.socialIcon}
                                size={IconSize}
                            />
                        </OutboundLink>
                        <OutboundLink
                            href="https://twitter.com/axlry_"
                            className={styles.socialLink}
                            rel="external"
                            title="AXLRY Twitter"
                        >
                            <FaTwitter
                                className={styles.socialIcon}
                                size={IconSize}
                            />
                        </OutboundLink>
                    </div>
                </div>
                <ul className={styles.legalList}>
                    <li className={styles.legalItem}>
                        <Link
                            className={styles.policyLink}
                            to="/support/terms"
                            title="Terms of Use"
                        >
                            Terms of Use
                        </Link>
                    </li>
                    <li className={styles.legalItem}>
                        <Link
                            className={styles.policyLink}
                            to="/support/privacy"
                            title="Privacy Policy"
                        >
                            Privacy Policy
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
