// Navbar component
import React from "react";
import { Link } from "gatsby";
import Logo from "../Images/SmallLogo";
import styles from "./Navbar.module.css";

const Navbar = () => (
    <div className={styles.navigation}>
        <div className={styles.navcontainer}>
            <Link to="/" className={styles.logo} title="AXLRY - Home">
                <Logo />
            </Link>
            <ul className={styles.navList}>
                <li className={styles.listItem}>
                    <Link
                        className={styles.navLink}
                        to="/#beta-signup"
                        title="Sign up for beta"
                    >
                        Sign Up
                    </Link>
                </li>
                <li className={styles.listItem}>
                    <Link
                        className={styles.navLink}
                        to="/support/contact"
                        title="Contact Us"
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    </div>
);

export default Navbar;
